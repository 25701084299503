import React from "react";
import Modal from "react-modal";
import { Trans } from "gatsby-plugin-react-i18next";
import IncubatorContact from "./IncubatorContact";

const customStyles = (wasFormSubmitted: boolean): ReactModal.Styles => ({
  overlay: {
    zIndex: "100",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    overflowY: "scroll",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "15px",
  },
  content: {
    maxWidth: "700px",
    background: "#010A12",
    border: "none",
    boxShadow: "0px 0px 30px rgba(255, 255, 255, 0.15)",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "24px",
    padding: "20px",
    height: wasFormSubmitted ? "480px" : "91vh",
  },
});

function SubmissionForm() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // lifting state since we need to adjust modal height if user already submitted form
  const [wasSubmitted, setWasSubmitted] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setWasSubmitted(false);
  }

  return (
    <div>
      <button
        className="btn-big mb-30 md:mb-0 disabled:bg-disabled disabled:cursor-not-allowed"
        onClick={openModal}
        disabled
      >
        <Trans>Apply Now</Trans>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles(wasSubmitted)}
        contentLabel="Incubator Form Modal"
      >
        <div className="relative">
          <button
            onClick={closeModal}
            className="absolute z-50 top-[10px] right-0 md:top-0 md:right-[10px]"
          >
            <img src="/images/icons/icon-close.png" width="20" height="20" alt="close" />
          </button>
          <IncubatorContact
            wasSubmitted={wasSubmitted}
            submittingForm={() => setWasSubmitted(true)}
          />
        </div>
      </Modal>
    </div>
  );
}

export default SubmissionForm;
