import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import SubmissionForm from "./SubmissionForm";

const IncubatorSelection = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="relative">
        <div className="w-full">
          <img
            className="w-full"
            srcSet="/images/oval_BG_down@2x.webp 2x, /images/oval_BG_down.webp 1x"
            src="/images/oval_BG_down.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-20">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <section className="relative">
        <div className="section-custom-top">
          <div className="container relative z-10">
            <h2 className="heading-2 pt-80 px-16">
              <Trans>How we select Startups</Trans>
            </h2>

            <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
              <li className="card-item card card-b">
                <div className="box-item bg-[#101820] py-32 px-16">
                  <div className="w-80 h-80 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/01.png"
                      alt=""
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20">
                    <p className="text-24 font-semibold">
                      <Trans>
                        Online <br />
                        Application
                      </Trans>
                    </p>
                  </div>
                  <hr className="line-separator" />
                  <p className="text-menu-white">
                    <Trans>Each startup applies by filling in the questionnaire below.</Trans>
                  </p>
                  <div className="mt-64">
                    <SubmissionForm />
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-300">
                <div className="box-item bg-[#101820] py-32 px-16">
                  <div className="w-80 h-80 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/02.png"
                      alt=""
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 h-[64px] flex justify-center items-center">
                    <p className="text-24 font-semibold">
                      <Trans> Interview</Trans>
                    </p>
                  </div>
                  <hr className="line-separator" />
                  <p className="text-menu-white">
                    <Trans>
                      After applying, we organize two 30-minute interviews to learn more about the
                      project.
                    </Trans>
                  </p>
                  <p className="text-menu-white italic mt-24">
                    <Trans>
                      * the team reserves the right to reject the project at the application stage
                    </Trans>
                  </p>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-600">
                <div className="box-item bg-[#101820] py-32 px-16">
                  <div className="w-80 h-80 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/03.png"
                      alt=""
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20">
                    <p className="text-24 font-semibold">
                      <Trans>
                        Selection <br />
                        Committee
                      </Trans>
                    </p>
                  </div>
                  <hr className="line-separator" />
                  <p className="text-menu-white">
                    <Trans>
                      Founders pitch their startup in front of a Milkomeda Incubator committee that
                      will select who will be joining the program.
                    </Trans>
                  </p>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-700">
                <div className="box-item bg-[#101820] py-32 px-16">
                  <div className="w-80 h-80 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/04.png"
                      alt=""
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 h-[64px] flex justify-center items-center">
                    <p className="text-24 font-semibold">
                      <Trans>Decision</Trans>
                    </p>
                  </div>
                  <hr className="line-separator" />
                  <p className="text-menu-white">
                    <Trans>
                      Each startup selected for the program will be informed by email and invited to
                      a kick-off meeting at which individual goals and deliverables will be defined.
                    </Trans>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default IncubatorSelection;
