import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Select from "react-select";
import { Link } from "gatsby";
import Spinner from "./Spinner";

type Option = { value: string; label: string };
const options = [
  { value: "defi", label: "DeFi" },
  { value: "nft", label: "NFT" },
  { value: "dao", label: "DAO" },
  { value: "gaming", label: "Gaming" },
];

const IncubatorContact = ({
  wasSubmitted,
  submittingForm,
}: {
  wasSubmitted: boolean;
  submittingForm: () => void;
}) => {
  const [inquiry, setInquiry] = React.useState(options[0]);
  const [status, setStatus] = React.useState<"idle" | "pending" | "success" | "rejected">("idle");

  const isLoading = status === "pending";
  const isSuccess = status === "success";
  const isError = status === "rejected";

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    setStatus("pending");
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());
    submittingForm();
    try {
      const response = await fetch("/api/incubator-application", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ fields: fieldValues }),
      });
      const data: any = await response.json();
      if (data?.createdTime) {
        setStatus("success");
      }
    } catch (error) {
      console.log(error);
      setStatus("rejected");
    }
  };

  return (
    <section>
      <div className="bg-blue-bg-dark">
        {!wasSubmitted && (
          <div className="pt-32 mb-32 md:pt-50 md:mb-50">
            <div>
              <h1 className="text-18 md:text-24 font-medium text-orange-light text-center uppercase tracking-widest mb-20">
                <Trans>Apply to Milkomeda Incubator</Trans>
              </h1>
              <p className="text-14 md:text-16 text-menu-white leading-normal text-center">
                <Trans>
                  Applications are reviewed on a first-come, first-serve basis. Priority treatment
                  will be given to applications received on or before 31.01.2023. Questions about
                  the grants or our process? Email&nbsp;
                  <a className="text-orange-light" href="mailto:grants@milkomeda.com">
                    grants@milkomeda.com
                  </a>
                  <br />
                  <br />
                  We look forward to learning more about what you're working on!
                </Trans>
              </p>
            </div>
            <div className="mx-auto w-full relative z-20 submission-form">
              <form className="mt-50" onSubmit={handleSubmit}>
                <div className="input-custom">
                  <label htmlFor="company-name">
                    <Trans>Company name *</Trans>
                  </label>
                  <input type="text" name="company-name" required />
                </div>

                <div className="input-custom mt-15">
                  <label htmlFor="email">
                    <Trans>Direct contact (e-mail) *</Trans>
                  </label>
                  <input type="email" name="direct-email" required />
                </div>

                <div className="input-custom mt-16">
                  <label htmlFor="website">
                    <Trans>Website URL *</Trans>
                  </label>
                  <input type="text" name="website-url" required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      What are you building? *
                      <br />
                      <span className="italic">
                        Maximum 50 characters, e.g. “Lending & borrowing on Ethereum”.
                      </span>
                    </Trans>
                  </label>
                  <textarea name="what-are-you-building" rows={3} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      Expand on what you're building. *
                      <br />
                      <span className="italic">
                        What's your value proposition? What stage are you at? What makes your
                        project unique? Share more details on how your product works or will work.
                        Maximum 3 paragraphs.
                      </span>
                    </Trans>
                  </label>
                  <textarea name="expand-on-what-youre-building" rows={3} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      Demo Link.
                      <br />
                      <span className="italic">
                        A link to a video or live demo of your product, if available.
                      </span>
                    </Trans>
                  </label>
                  <textarea name="demo-link" rows={3} required />
                </div>

                <div className="md:flex flex-row justify-between">
                  <div className="input-custom mt-16  md:w-290">
                    <label htmlFor="first name">
                      <Trans>First Name *</Trans>
                    </label>
                    <input type="text" name="first-name" required />
                  </div>
                  <div className="input-custom mt-16 md:w-290">
                    <label htmlFor="last name">
                      <Trans>Last Name *</Trans>
                    </label>
                    <input type="text" name="last-name" required />
                  </div>
                </div>

                <div className="input-custom mt-16">
                  <label htmlFor="title">
                    <Trans>Your Title (Founder, CEO, CPO, etc.) *</Trans>
                  </label>
                  <input type="text" name="title" required />
                </div>

                <div className="md:flex flex-row justify-between">
                  <div className="input-custom mt-16  md:w-290">
                    <label htmlFor="Linkedin URL">
                      <Trans>Linkedin URL</Trans>
                    </label>
                    <input type="text" name="linkedin-url" />
                  </div>
                  <div className="input-custom mt-16 md:w-290">
                    <label htmlFor="Email">
                      <Trans>Email</Trans>
                    </label>
                    <input type="email" name="email" />
                  </div>
                </div>

                <div className="input-custom mt-16">
                  <label htmlFor="expertise">
                    <Trans>Summarize your expertise (technical, business, etc) *</Trans>
                  </label>
                  <input type="text" name="summary-expertise" required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      How many founders does the company have? *
                      <br />
                      <span className="italic">
                        Share details of other founders, their roles and stack.
                      </span>
                    </Trans>
                  </label>
                  <textarea name="how-many-founders" rows={3} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      What is the equity breakdown between each founder? *
                      <br />
                      <span className="italic">If equity is not equally divided, clarify why.</span>
                    </Trans>
                  </label>
                  <textarea name="what-equity-breakdown" rows={3} required />
                </div>

                <div className="input-custom mt-16">
                  <label htmlFor="website">
                    <Trans>
                      Let us know if this is your first startup and if you are 100% involved in it.
                      *
                    </Trans>
                  </label>
                  <input type="text" name="is-it-your-first-startup" required />
                </div>

                <div className="input-custom mt-16">
                  <label htmlFor="website">
                    <Trans>What type of project best describes your idea:</Trans>
                  </label>
                  <Select
                    name="type-of-project-describe-best"
                    options={options}
                    className="contact-inquiry-select"
                    classNamePrefix="react-select"
                    getOptionLabel={(item) => item.label}
                    getOptionValue={(item) => item.value}
                    value={inquiry}
                    onChange={(item) => {
                      setInquiry(item as Option);
                    }}
                  />
                </div>

                <div className="input-custom mt-16">
                  <label htmlFor="website">
                    <Trans>
                      What ecosystem are you building on? *
                      <br />
                      <span className="italic">
                        If you are currently building on multiple ecosystems, please indicate which
                        one is your main focus.
                      </span>
                    </Trans>
                  </label>
                  <input type="text" name="ecosystem-building-on" required />
                </div>

                <div className="input-custom mt-16">
                  <label htmlFor="website">
                    <Trans>Are you committed full-time to the project? *</Trans>
                  </label>
                  <input type="text" name="are-you-committed-fulltime" required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      When did you start working on this idea and why did you choose it? *
                    </Trans>
                  </label>
                  <textarea name="when-start-working-why-choose-it" rows={3} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      Why are you the right team to solve this problem? *
                      <br />
                      <span className="italic">
                        We are looking for teams with 2 or more people, and at least 1 technical
                        member.
                      </span>
                    </Trans>
                  </label>
                  <textarea name="why-are-you-right-team" rows={3} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      What other projects are solving this problem today or could if they wanted to?
                      Why will your project succeed against them? *
                    </Trans>
                  </label>
                  <textarea name="what-other-projects-are-solving" rows={3} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>
                      Have you received any investments so far? If so, from which investors and at
                      what valuations? *
                    </Trans>
                  </label>
                  <textarea name="have-you-received-investments" rows={3} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>Why are you applying to Milkomeda’s Incubator? *</Trans>
                  </label>
                  <textarea name="why-are-you-applying-milkomeda" rows={2} required />
                </div>

                <div className="input-custom mt-15">
                  <label>
                    <Trans>Did anyone refer you to Milkomeda’s Incubator? *</Trans>
                  </label>
                  <textarea name="did-anyone-refer-you" rows={2} required />
                </div>

                <button className="btn-big mt-40 w-full" type="submit" disabled={isLoading}>
                  <Trans>Submit</Trans>
                </button>
              </form>
            </div>
          </div>
        )}
        {/* Success Message */}
        {isSuccess && (
          <div className="flex flex-col justify-center px-24 md:px-40 pt-50 text-center">
            <p className="text-white font-medium font-base text-24 md:text-32 mb-20">
              <Trans>Thank you for sending your application to Milkomeda Incubator.</Trans>
            </p>
            <p className="text-menu-white font-base text-14 md:text-16">
              <Trans>
                We will review your application and reply within the next few days. In the meantime,
                feel free to contact us further with any questions or concerns at
              </Trans>
            </p>
            <a
              className="text-18 md:text-24 text-white font-base font-medium py-24"
              href="mailto:grants@milkomeda.com"
            >
              grants@milkomeda.com
            </a>
            <p className="text-menu-white font-base text-14 md:text-16">
              <Trans>We look forward to hearing from you!</Trans>
            </p>
            <Link className="btn-big mt-40 w-full" to="/">
              <Trans>Back to home page</Trans>
            </Link>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center justify-center px-24 md:px-40 pt-[100px]">
            <Spinner className="w-40 h-40" />
          </div>
        )}
        {/* Error Message */}
        {isError && (
          <div className="flex flex-col justify-center px-24 md:px-40 pt-50 text-center">
            <p className="text-white font-medium font-base text-24 md:text-32 mb-20">
              <Trans>Something went wrong...</Trans>
            </p>
            <p className="text-menu-white font-base text-14 md:text-16">
              <Trans>
                We couldn’t process your request at this time. Please review that all the submission
                fields are completed and try again. <br />
                For further assistance, please reach out to
              </Trans>
            </p>
            <a
              className="text-18 md:text-24 text-white font-base font-medium py-24"
              href="mailto:grants@milkomeda.com"
            >
              grants@milkomeda.com
            </a>
            <p className="text-menu-white font-base text-14 md:text-16">
              <Trans>or send us a request through our contact form.</Trans>
            </p>

            <Link className="btn-big mt-40 w-full" to="/support">
              Contact Us
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default IncubatorContact;
