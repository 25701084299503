import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import SubmissionForm from "./SubmissionForm";
import { IncubatorDisclaimer } from "./IncubatorLanding";

const IncubatorCrew = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="relative">
        <div className="w-full">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-20">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <section id="features" className="relative bg-blue-bg-dark">
        <div className="space-custom"></div>
        <div className="container relative z-20">
          <h2 className="heading-2">
            <Trans>
              WITH OVER A DECADE OF EXPERIENCE ENABLING STARTUPS TO ACHIEVE THEIR POTENTIAL,
              INTRODUCING YOUR FLIGHT CREW
            </Trans>
          </h2>
          <div className="crew-list flex-wrap">
            {/* dcSpark row */}
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/nico2x.webp 2x, /images/crew/nico.webp 1x"
                src="/images/crew/nico.webp"
                width="200"
                height="200"
                alt="nico"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Nicolas Arqueros</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>Co-founder and CEO</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dcSpark</Trans>
              </p>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/sebastien2x.webp 2x, /images/crew/sebastien.webp 1x"
                src="/images/crew/sebastien.webp"
                width="200"
                height="200"
                alt="sebastien"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Sebastien Guillemot</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>Co-founder and CTO</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dcSpark</Trans>
              </p>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/robertk2x.webp 2x, /images/crew/robertk.webp 1x"
                src="/images/crew/robertk.webp"
                width="200"
                height="200"
                alt="robert kornacki"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Robert Kornacki</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>Co-founder and CSO</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dcSpark</Trans>
              </p>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/iwo2x.webp 2x, /images/crew/iwo.webp 1x"
                src="/images/crew/iwo.webp"
                width="200"
                height="200"
                alt="iwo"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Iwo Pietak</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>VP of Release</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dcSpark</Trans>
              </p>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                src="/images/crew/bullish2x.png"
                width="200"
                height="200"
                alt=""
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Bullish Dumpling</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>Brand Manager</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dcSpark</Trans>
              </p>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/robertl2x.webp 2x, /images/crew/robertl.webp 1x"
                src="/images/crew/robertl.webp"
                width="200"
                height="200"
                alt="robert licau"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Robert Licau</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>Head of Design</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dcSpark</Trans>
              </p>
            </div>
            {/* dlab row */}
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/nick2x.webp 2x, /images/crew/nick.webp 1x"
                src="/images/crew/nick.webp"
                width="200"
                height="200"
                alt="nick"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Nick Plante</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>Partner</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dlab</Trans>
              </p>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/shawn2x.webp 2x, /images/crew/shawn.webp 1x"
                src="/images/crew/shawn.webp"
                width="200"
                height="200"
                alt="shawn"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Shawn Broderick</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>GP, SOSV</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dlab</Trans>
              </p>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 flex flex-col gap-4 text-center mb-24">
              <img
                srcSet="/images/crew/paul2x.webp 2x, /images/crew/paul.webp 1x"
                src="/images/crew/paul.webp"
                width="200"
                height="200"
                alt="paul"
                className="flex align-center m-auto"
              />
              <p className="text-24 font-semibold">
                <Trans>Paul Saint-Donat</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>Principal</Trans>
              </p>
              <p className="text-menu-white leading-normal">
                <Trans>dlab</Trans>
              </p>
            </div>
          </div>

          {/* Company info */}
          <div className="flex flex-wrap mt-96 justify-center gap-48">
            <div className="max-w-[416px] flex flex-col gap-48">
              <div className="h-[48px] flex flex-col justify-center">
                <img
                  src="/images/icons/acceleration/dcSparkAccLogo.webp"
                  width="200"
                  height="48"
                  alt="dcSpark acceleration logo"
                  className="flex m-auto my-0"
                />
              </div>
              <p className="text-menu-white leading-normal text-center">
                <Trans>
                  We're Crypto Ecosystem Builders. We are a product based Blockchain company focused
                  on building critical products and solutions on top of the most promising crypto
                  projects in the space. Our products empower our users with a great experience
                  thanks to our domain expertise in the full Blockchain stack.
                </Trans>
              </p>
            </div>
            <div className="max-w-[416px] flex flex-col gap-48 mb-80">
              <img
                src="/images/icons/acceleration/dlabAccLogo.webp"
                width="110"
                height="48"
                alt="dlab acceleration logo"
                className="flex align-center m-auto"
              />
              <p className="text-menu-white leading-normal text-center">
                <Trans>
                  dlab was created by SOSV in 2018 to provide capital and resources to startups who
                  are creating more open, transparent, and participatory systems using blockchains
                  and other decentralization technologies. SOSV provides up to $250k in venture
                  funding to pre-seed and seed-stage startups across the globe through our
                  fully-remote program.
                </Trans>
              </p>
            </div>
          </div>

          <h2 className="heading-2">
            <Trans>MENTOR</Trans>
          </h2>

          <div className="w-full sm:w-1/2 lg:w-1/4 flex flex-col gap-4 text-center mb-24 mx-auto">
            <img
              srcSet="/images/crew/galen-law-kun2x.png 2x, /images/crew/galen-law-kun2x.png 1x"
              src="/images/crew/galen-law-kun2x.png"
              width="200"
              height="200"
              alt="bullish"
              className="flex align-center mx-auto"
            />
            <p className="text-24 font-semibold">
              <Trans>Galen Law-Kun</Trans>
            </p>
            <p className="leading-none text-menu-white">
              <Trans>Co-Founder</Trans>
            </p>
            <p className="text-menu-white leading-normal">
              <Trans> Double Peak</Trans>
            </p>
          </div>
        </div>

        <div className="container flex justify-center mt-64 relative z-20">
          <SubmissionForm />
        </div>

        <IncubatorDisclaimer />

        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default IncubatorCrew;
