import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import SubmissionForm from "./SubmissionForm";

export const IncubatorDisclaimer = () => {
  return (
    <p className="max-w-[600px] mx-auto mt-40 text-menu-white leading-normal italic text-14 text-center">
      <Trans>
        Project selection is ongoing and does not have a set end date. Milkomeda Incubator is
        constantly accepting and reviewing submissions, and decisions are made on a rolling basis as
        they are received.
      </Trans>
    </p>
  );
};
const IncubatorLanding = () => {
  return (
    <>
      <section className="w-full relative z-20 -mb-10">
        <div className="absolute w-full h-full top-0 left-0 -z-10">
          <img
            className="w-full h-full object-cover"
            srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
            src="/images/page-bg.webp"
            width="1600"
            height="502"
            alt="page-bg"
          />
        </div>
        <div className="container pt-110 lg:pt-145 sc-up-mb z-20 relative">
          <div className="text-center animate-from-bottom">
            <h1 className="heading-3 heading-1-2">
              <Trans>
                Incubating the best <br />
                Web3 ideas
              </Trans>
            </h1>
            <p className="align-center mt-24 text-menu-white font-normal md:text-20 max-w-[700px] ml-auto mr-auto">
              <Trans>
                Milkomeda Incubator is positioned to be the key incubation program to bootstrap your
                startup on Cardano and Algorand.
              </Trans>
            </p>
            <p className="align-center text-menu-white font-normal md:text-20 max-w-[700px] ml-auto mr-auto">
              <Trans>
                Receive grants and support from the Milkomeda Foundation while gaining access to our
                extensive network of mentors, investors, protocol developers, and key leaders in the
                ecosystems.
              </Trans>
            </p>
            <p className="text-24 my-24 font-semibold">
              <Trans>APPLICATIONS FOR BATCH 2 CLOSED</Trans>
            </p>
            <div className="md:flex-row mt-40 md:mt-40">
              <SubmissionForm />
            </div>
            <IncubatorDisclaimer />
            <div className="flex md:flex-row justify-center items-center mt-24 lg:mt-24 text-menu-white text-center relative z-10">
              <Trans>Powered by</Trans>
              <div className="text-center mx-5">
                <a href="https://www.dcspark.io/" target="_blank" rel="noopener" className="link">
                  <Trans>dcSpark</Trans>
                </a>
              </div>
              <Trans> & </Trans>
              <div className="text-center mx-5">
                <a href="https://dlab.vc/" target="_blank" rel="noopener" className="link">
                  <Trans>dlab</Trans>
                </a>
              </div>
            </div>
          </div>

          <div className="space-custom"></div>
        </div>

        <div className="w-full -z-10">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default IncubatorLanding;
