import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import SubmissionForm from "./SubmissionForm";

const IncubatorIntro = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="relative">
        <div className="bg-blue-bg-dark section-custom-top">
          <div className="container relative z-10">
            <div className="mx-auto w-full max-w-[480px] relative z-20 text-center">
              <p className="text-24 mt-32 font-semibold">
                <Trans>
                  Milkomeda is the go-to protocol for entrepreneurs and developers to deploy their
                  DApps to multiple non-compatible ecosystems. Join our Incubator to expand the
                  ecosystem and gain access to millions of users.
                </Trans>
              </p>
              <p className="mt-20 text-menu-white leading-normal">
                <Trans>
                  As development progresses, Milkomeda will expand to offer L2 solutions (rollups)
                  for several major blockchains including Cardano, Algorand and Solana.
                  <br />
                  Our Incubator program is intended to provide startups building on Milkomeda with
                  grant funding, priority access to resources, promotion and support to help your
                  startup take flight.
                </Trans>
              </p>
              <p className="text-24 mt-32 font-semibold">
                <Trans>
                  Join us to be among the second wave of startups launching on the Milkomeda
                  protocol for Cardano and Algorand.
                </Trans>
              </p>
              <div className="md:flex-row mt-40 md:mt-40 text-center">
                <SubmissionForm />
              </div>
            </div>
          </div>
        </div>

        {/* Type of projects */}
        <div className="bg-blue-bg-dark">
          <div className="container z-20">
            <h2 className="heading-2 pt-80 px-16">
              <Trans>Type of projects we are looking for:</Trans>
            </h2>
            <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
              <li className="card-item card card-b">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/defi.png"
                      alt="DeFi"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>DeFi</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-300">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/nfts.png"
                      alt="NFTs"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>NFTs</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-600">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/daos.png"
                      alt="DAOs"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>DAOs</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-700">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/incubator/gaming.png"
                      alt="Gaming"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-40 font-semibold">
                      <Trans>Gaming</Trans>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Selected Companies Receive */}
        <div className="bg-blue-bg-dark">
          <div className="container z-20">
            <h2 className="heading-2 pt-80 px-16">
              <Trans>Selected Companies Receive</Trans>
            </h2>
            <ul className="section-custom-top flex flex-wrap justify-center gap-18 relative z-20">
              <li className="w-full md:w-[48%] lg:w-[246px] card card-b">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/acceleration/funding.png"
                      alt="Gaming"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Funding</Trans>
                    </p>
                    <ul className="list-inside">
                      <li className="mt-10 text-menu-white leading-normal">
                        <Trans>$10k USDC funding for Cardano</Trans>
                      </li>
                      <li className="mt-10 text-menu-white leading-normal">
                        <Trans>$10k USDC funding for Algorand</Trans>
                      </li>
                    </ul>
                    <p className="mt-10 text-menu-white leading-normal italic">
                      <Trans>*they are not exclusive</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="w-full md:w-[48%] lg:w-[246px] card card-b animation-delay-300 mt-24 md:mt-0">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/acceleration/benefits.webp"
                      alt="Benefits"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Designed to be lightweight</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        On-going access to benefits from our key partners.
                        <br />
                        On-going hands-on programming, coaching, mentorship, and priority technical
                        support from dcSpark and dlab.
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="w-full md:w-[48%] lg:w-[246px] card card-b animation-delay-600 mt-24 lg:mt-0">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/acceleration/promotion.webp"
                      alt="Promotion"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Featured status and promotion </Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        Promotion of the project within Milkomeda’s and dlab’s communication
                        channels.
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="w-full md:w-[48%] lg:w-[246px] card card-b animation-delay-700">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/hackathon/format.png"
                      alt="Advisory"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Advisory</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        Our team consists of Web3 experts with complementary backgrounds.
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="w-full md:w-[48%] lg:w-[246px] card card-b animation-delay-800 mt-24">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/acceleration/access.webp"
                      alt="Gaming"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Access to future funding options</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        Access to future funding options from dlab and program partner VCs investing
                        into the Milkomeda ecosystem.
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="w-full md:w-[48%] lg:w-[246px] card card-b animation-delay-900 mt-24">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/acceleration/tech.png"
                      alt="Exclusive Access to the Latest Technologies"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Exclusive Access to the Latest Technologies</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        Participants will be among the first to gain access to the newest
                        technologies developed by Milkomeda.
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="w-full md:w-[48%] lg:w-[246px] card card-b animation-delay-900 mt-24">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <StaticImage
                      src="../../static/images/icons/acceleration/global.webp"
                      alt="Global Network"
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Global Network</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        You will join a network of specialists, global entrepreneurs, and
                        experienced international mentors.
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default IncubatorIntro;
